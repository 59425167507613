"use client";

import { usePathname } from "next/navigation";

const disabledPaths = ["/demo"];

export default function EmbedChatbot() {
    const pathname = usePathname();

    if (disabledPaths.some((path) => pathname.includes(path))) {
        return null;
    }

    return (
        <script
            src="https://chat.yedalabs.io/embed.js"
            data-env="prod"
            data-chatbot-id="78f2df29-6aab-4620-88ca-ebf0bbd88b7e"
            async
        ></script>
    );
}
