"use client";

import { QueryProvider } from "../queryProvider";
import AltProvider from "../tolgee/altProvider";
import ToastProvider from "./toastProvider";
import InitDatadog from "../initDatadog";
import { datadogEnabled } from "../utils";
import { AuthProvider } from "@/features/auth/hooks/useAuth";
import { Session } from "next-auth";
import { TolgeeNextProvider } from "../tolgee/client";
import PostHogProvider from "../posthog/initPosthog";
import EmbedChatbot from "../embedChatbot";

interface ProviderProps {
    children: React.ReactNode;
    session: Session | null;
    locale: string;
    locales: Record<string, any>;
    isMobile: boolean;
}

export const GlobalProviders = ({
    children,
    session,
    locale,
    locales,
    isMobile,
}: ProviderProps) => {
    return (
        <PostHogProvider>
            <QueryProvider>
                {datadogEnabled && <InitDatadog />}
                <EmbedChatbot />
                <AuthProvider session={session} isMobile={isMobile}>
                    <TolgeeNextProvider locale={locale} locales={locales}>
                        <ToastProvider />

                        <AltProvider>{children}</AltProvider>
                    </TolgeeNextProvider>
                </AuthProvider>
            </QueryProvider>
        </PostHogProvider>
    );
};
