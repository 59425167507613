"use client";

import posthog from "posthog-js";
import { PostHogProvider as PHProvider } from "posthog-js/react";
import { ReactNode, useEffect } from "react";

import dynamic from "next/dynamic";

const PostHogPageView = dynamic(() => import("./postHogPageView"), {
    ssr: false,
});

export default function PostHogProvider({ children }: { children: ReactNode }) {
    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
            api_host: "/ingest",
            ui_host: "https://us.posthog.com",
            capture_pageview: false,
            capture_pageleave: true,
            autocapture: false,
        });
    }, []);

    return (
        <PHProvider client={posthog}>
            <PostHogPageView />
            {children}
        </PHProvider>
    );
}
